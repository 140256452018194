import React from 'react'
import Navbar from '../components/Navbar'
import Pistonlu_comp from '../components/Pistonlu_comp'
import Footer from '../components/Footer'

function Pistonlu_Urunler() {
  return (
    <div>
        <Navbar/>
        <Pistonlu_comp/>
        <Footer/>
    </div>
  )
}

export default Pistonlu_Urunler
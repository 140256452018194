import React from 'react'
import Navbar from '../components/Navbar'
import Vidali_comp from '../components/Vidali_comp'
import Footer from '../components/Footer'

function Vidali_Urunler() {
  return (
    <div>
        <Navbar/>
        <Vidali_comp/>
        <Footer/>


    </div>
  )
}

export default Vidali_Urunler
import React from 'react'
import Navbar from '../components/Navbar'
import Urunler_comp from '../components/Urunler_comp'
import Footer from '../components/Footer'

function Urunler() {
  return (
    <div>
        <Navbar/>
        <Urunler_comp/>
        <Footer/>
    </div>
  )
}

export default Urunler
import React from 'react'
import Navbar from '../components/Navbar'
import Hakkimizda_comp from '../components/Hakkimizda_comp'
import Footer from '../components/Footer'

function Hakkimizda() {
  return (
    <div>
        <Navbar/>
        <Hakkimizda_comp/>
        <Footer/>
    </div>
  )
}

export default Hakkimizda